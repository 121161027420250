import { template as template_061768ff7b1b44d287ae4ac7445e6bb2 } from "@ember/template-compiler";
const FKLabel = template_061768ff7b1b44d287ae4ac7445e6bb2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
