import { template as template_d63c7a7f1915488d8ad08edce95e52fa } from "@ember/template-compiler";
const FKText = template_d63c7a7f1915488d8ad08edce95e52fa(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
