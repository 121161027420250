import { template as template_1aed3584700d40128970a170d739a074 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_1aed3584700d40128970a170d739a074(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
