import { template as template_bdfcd1047689401f8ca70ccf19867901 } from "@ember/template-compiler";
const SidebarSectionMessage = template_bdfcd1047689401f8ca70ccf19867901(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
