import { template as template_31982ff724434e629ece4e1423f4e8ec } from "@ember/template-compiler";
const WelcomeHeader = template_31982ff724434e629ece4e1423f4e8ec(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
